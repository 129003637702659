import React from "react"
import { Text, Button } from "deskie-ui"
import { navigate } from "gatsby"
import Container from "../components/Container"
import PageWrapper from "../components/PageWrapper"
import styled from "styled-components"
import { StyledBenefitsCard } from "../components/BenefitsCard"

const Header = styled(Container)`
  margin-top: -6rem;
  padding-top: 8rem;
  padding-bottom: 6rem;

  h1 {
    color: white;
    font-weight: 600;
    font-size: min(
      max(1.75rem, calc(1.75rem + ((1vw - 0.4rem) * 1.875))),
      2.5rem
    );
    line-height: 1.3;
    margin: 0 0 0.5rem;
  }

  p {
    color: rgba(255, 255, 255, 0.7);
  }

  background-image: url("/paths.svg");
  background-size: auto 160%;
  background-position: 100% 0%;
  background-repeat: no-repeat;
`

const Content = styled(Container)`
  h2 {
    color: ${props => props.theme.colors.primary};
  }

  & > * + * {
    margin-top: 1em;
    display: block;
  }

  ul {
    padding-left: 1.5rem;
  }

  a {
    color: ${props => props.theme.colors.primary};
  }

  .button {
    margin-top: 2rem;
  }
`

const OrderdList = styled.ol<{ count: string }>`
  list-style: none;
  padding-left: 2.5rem;
  counter-reset: list-item-counter;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.75;
  color: ${props => props.theme.colors.foreground};

  & > li {
    counter-increment: list-item-counter;
    position: relative;
    margin-top: 1rem;
  }
  & > li::before {
    content: ${props => `"${props.count}." counter(list-item-counter) "."`};
    position: absolute;
    left: -2rem;
  }

  & > ol > li {
    list-style: lower-latin;
  }
`

function TermsAndConditions() {
  return (
    <>
      <Header>
        <Text h1>Algemene Voorwaarden</Text>
      </Header>
      <PageWrapper>
        <Content>
          <Text p>
            Deze algemene voorwaarden zijn van toepassing op iedere aanbieding
            of offerte van Deskie. Het product Deskie is van Arcady B.V.
          </Text>

          <Text h2>Artikel 1. Definities</Text>
          <Text>
            <ul>
              <li>
                <b>Deskie:</b> Deskie is een product van Arcady B.V., gevestigd
                te Zwolle aan de Burgemeester Roelenweg 13K, Kvk 08168608
              </li>
              <li>
                <b>Abonnement:</b> het type gebruiksrecht op de software dat
                afnemer bij Arcady B.V. afneemt.
              </li>
              <li>
                <b>Afnemer:</b> Jij of jouw bedrijf als gebruiker(s) van de
                software;
              </li>
              <li>
                <b>Gebruiker:</b> De natuurlijke of rechtspersoon in de
                uitoefening van beroep of bedrijf, die een overeenkomst sluit
                met Deskie
              </li>
              <li>
                <b>Gegevens:</b>
              </li>
              <li>
                <b>Software:</b> de softwareapplicatie genaamd “Deskie”, een
                webapplicatie waar gebruikers hun werkplekken mee kunnen boeken.
              </li>
            </ul>
          </Text>
          <Text h2>Artikel 2. Overeenkomst met Deskie</Text>
          <OrderdList count="2">
            <li>Deskie sluit op de volgende manieren overeenkomsten:</li>
            <ol>
              <li>
                Klant kan contact opnemen met Deskie via de Website, App, e-mail
                of telefoon voor een van de aangeboden Diensten. Deskie stelt
                vervolgens een offerte op, welke door Afnemer binnen de gestelde
                termijn schriftelijk aanvaard dient te worden. Overeenkomst komt
                tot stand na deze aanvaarding;
              </li>
              <li>
                Deskie aanvaardt een bestelling en/of een daartoe bestemd
                bestelformulier afkomstig van Afnemer schriftelijk. Met deze
                aanvaarding komt Overeenkomst tot stand;{" "}
              </li>
              <li>Deskie sluit een (raam-)contract met Afnemer. </li>
            </ol>
            <li>
              Indien Afnemer aantekeningen maakt of reacties geeft op de offerte
              van Deskie dan maken zij geen deel uit van Overeenkomst, tenzij
              Deskie deze schriftelijk bevestigt.{" "}
            </li>
            <li>
              Indien Deskie een bevestiging naar Afnemer stuurt, is deze
              bevestiging beslissend voor de inhoud en uitleg van Overeenkomst,
              onder voorbehoud van kennelijke verschrijvingen. Deskie kan niet
              aan zijn aanbod worden gehouden indien Afnemer redelijkerwijs kan
              begrijpen dat het aanbod, dan wel een onderdeel daarvan, een
              kennelijke vergissing of verschrijving bevat.
            </li>
            <li>
              Deskie is niet aansprakelijk voor schade, van welke aard ook,
              ontstaan doordat Deskie is uitgegaan van door de Afnemer
              verstrekte onjuiste en/of onvolledige gegevens, tenzij deze
              onjuistheden of onvolledigheid voor Deskie kenbaar behoorde te
              zijn.
            </li>
          </OrderdList>
          <Text h2>Artikel 3. Gebruikers</Text>
          <OrderdList count="3">
            <li>
              Deskie verleent Afnemer voor de duur van Overeenkomst en onder
              voorwaarden van Overeenkomst een beperkt, herroepelijk,
              niet-exclusief, niet (aan derden) over draagbaar recht (licentie)
              tot het gebruik van de (software) producten van Deskie.{" "}
            </li>
            <li>
              Afnemer is uitsluitend bevoegd het gebruiksrecht over te dragen
              en/of uit te besteden indien het een dochteronderneming van
              Afnemer betreft.{" "}
            </li>
            <li>
              Afnemer verplicht zich met deze Overeenkomst het gebruiksrecht
              en/of de (software) producten uitsluitend te gebruiken voor het
              doel waarvoor dit gebruiksrecht en/of de (software) producten door
              Deskie zijn ontwikkeld en door documentatie is aangegeven.{" "}
            </li>
            <li>
              Afnemer is verantwoordelijk voor het juiste gebruik en de juiste
              toepassing van de door Deskie geleverde Diensten evenals voor de
              controle -en beveiligingsprocedures (waaronder begrepen het
              regelmatig maken van back-ups van databestanden) en een adequaat
              systeembeheer. Desbetreffende updates dient Afnemer tijdig en op
              juiste wijze te installeren.{" "}
            </li>
            <li>
              Het is Afnemer niet toegestaan software van Deskie geheel of
              gedeeltelijk te integreren in of samenvoegen met programmatuur of
              elektronische gegevensverzamelingen van derden of afnemer zelf,
              tenzij hiervoor uitdrukkelijk schriftelijk toestemming is verleend
              door Deskie.{" "}
            </li>
            <li>
              De software van Deskie mag niet onderworpen zijn aan enige vorm
              van decompilatie, reverse engineering of enigerlei andere vorm van
              vertaling of bewerking van de programmacode.{" "}
            </li>
            <li>
              Deskie is gerechtigd onderzoek in te stellen naar een degelijk
              gebruik van de software. Afnemer dient medewerking en toegang tot
              alle software, documentatie en andere materialen te verlenen,
              alsmede tot elke locatie waar de door Deskie verstrekte producten
              zich bevinden.
            </li>
          </OrderdList>
          <Text h2>Artikel 4. Duur en beëindiging van de overeenkomst</Text>
          <OrderdList count="4">
            <li>
              Als Afnemer de gebruiksrechtvergoeding per maand betaalt, zoals
              geregeld in van deze Voorwaarden, wordt de Overeenkomst tussen
              Partijen aangegaan voor de duur van één maand. Als Afnemer de
              gebruiksrechtvergoeding per jaar betaalt, wordt de Overeenkomst
              tussen Partijen aangegaan voor de duur één jaar. Indien in de
              Overeenkomst een andere termijn is gespecificeerd komt de
              Overeenkomst tot stand voor de duur zoals gespecificeerd in de
              Overeenkomst.{" "}
            </li>
            <li>
              Tenzij Afnemer of Deskie de Overeenkomst voor het einde van de
              looptijd van de Overeenkomst, zoals bedoeld in artikel 2.1,
              conform de eisen van artikel 4.3 heeft opgezegd, wordt de
              Overeenkomst steeds stilzwijgend verlengd voor dezelfde duur als
              waarvoor deze in eerste instantie is aangegaan.{" "}
            </li>
            <li>
              Afnemer kan de Overeenkomst op ieder gewenst moment per e-mail of
              telefonisch beëindigen, welke wordt bevestigd door een
              bevestigingsmail vanuit Deskie. De Overeenkomst eindigt in beide
              gevallen op de laatste dag van de looptijd van de Overeenkomst. De
              Overeenkomst kan niet tussentijds worden beëindigd.{" "}
            </li>
            <li>
              De Overeenkomst kan, zonder enige voorafgaande opzegtermijn, en
              met onmiddellijke ingang, ontbonden worden door Deskie, vanaf de
              dag dat:
            </li>
            <ol>
              <li>
                het faillissement van Afnemer wordt aangevraagd dan wel Afnemer
                zelf haar faillissement aanvraagt;{" "}
              </li>
              <li>Afnemer in staat van faillissement wordt verklaard; </li>
              <li>Afnemer tot boedelafstand overgaat; </li>
              <li>
                tegen de Afnemer surseance van betaling wordt verleend of een
                regeling met haar crediteuren treft;{" "}
              </li>
              <li>
                Afnemer de vrije beschikking over (een substantieel deel van)
                haar vermogen verliest, bijvoorbeeld door beslaglegging;{" "}
              </li>
              <li>
                de liquidatie van Afnemer wordt aangevangen, dan wel een
                vordering tot ontbinding van Afnemer wordt ingesteld of een
                ontbindingsbesluit ten aanzien van Afnemer wordt genomen, tenzij
                er sprake is van een rechtsopvolger;{" "}
              </li>
              <li>
                Afnemer enige uit kracht van de wet op hem rustende verplichting
                niet of niet geheel nakomt.{" "}
              </li>
              <li>
                Afnemer in strijd handelt met hetgeen bepaald is in artikel 3
                van deze Voorwaarden.
              </li>
            </ol>
            <li>
              Beëindiging of ontbinding van de Overeenkomst laat verschuldigde
              betalingsverplichtingen van Afnemer onverlet.
            </li>
            <li>
              Deskie is bevoegd de nakoming van de verplichtingen op te schorten
              of Overeenkomst te ontbinden, indien Afnemer de verplichtingen uit
              Overeenkomst niet, niet volledig of niet tijdig nakomt, dan wel
              dat Deskie goede grond heeft te vrezen dat Afnemer in deze
              verplichtingen zal tekortschieten.{" "}
            </li>
            <li>
              Na beëindiging van de Overeenkomst wordt de toegang tot de
              Software voor Afnemer geblokkeerd en kan deze de Software niet
              meer gebruiken. Het Account en Gebruikers van Afnemer zullen
              worden opgeheven, en de Documenten en Gegevens zullen worden
              verwijderd, zonder dat hierdoor enige schadeplichtigheid van
              Deskie jegens Afnemer en/of derden ontstaat.
            </li>
            <li>
              Na beëindiging blijven alle bepalingen uit deze Voorwaarden die
              naar hun aard ook na beëindiging werking hebben, volledig van
              kracht. Het gaat daarbij bijvoorbeeld – maar niet uitsluitend – om
              bepalingen over aansprakelijkheid, vrijwaring, intellectuele
              eigendomsrechten, privacy en de verantwoordelijkheid voor
              Documenten en overeenkomsten van Afnemer.{" "}
            </li>
          </OrderdList>
          <Text h2>Artikel 5. Prijzen en vergoeding</Text>
          <OrderdList count="5">
            <li>
              Alle genoemde bedragen in de offerte zijn exclusief omzetbelasting
              (BTW), tenzij anders vermeld.
            </li>
            <li>
              Voor eventuele andere verschuldigde betalingen hanteert Deskie een
              betalingstermijn van 30 dagen na factuurdatum, zonder dat korting,
              verrekening, opschorting of schuldcompensatie aan de kant van
              Afnemer is toegestaan.{" "}
            </li>
            <li>
              Afnemer zal enkel door het verstrijken van de redelijke
              betalingstermijn zoals bedoeld in artikel 4.2 in verzuim zijn,
              zonder dat daartoe enige sommatie of ingebrekestelling dan wel
              rechterlijke tussenkomst nodig is.{" "}
            </li>
            <li>
              Deskie heeft het recht om kennelijk foutieve verschrijvingen in de
              prijsopgave te herstellen. Afnemer heeft de plicht om onjuistheden
              in de vermelde of verstrekte betaalgegevens onverwijld aan Deskie
              mede te delen.
            </li>
            <li>
              Betaling geschiedt jaarlijks bij vooruitbetaling door Afnemer aan
              Deskie, tenzij anders overeengekomen. Afnemer dient de factuur
              binnen dertig (30) dagen na de factuurdatum te voldoen.{" "}
            </li>
            <li>
              Indien in Overeenkomst jaarlijkse (licentie)kosten zijn
              overeengekomen, is Deskie gerechtigd deze kosten jaarlijks te
              verhogen met een percentage dat is gebaseerd op de Consumenten
              Prijs Index (CPI) van het Nederlands Centraal Bureau voor de
              Statistiek (CBS). Indien Afnemer met de nieuwe tarieven niet
              akkoord wenst te gaan kan zij de Overeenkomst schriftelijk
              beëindigen. Een verhoging van de prijzen wordt minimaal 1 (één)
              maand voor de verhoging kenbaar gemaakt aan Afnemer.{" "}
            </li>
            <li>
              Indien Afnemer in gebreke blijft in de tijdige betaling van een
              factuur, dan is Afnemer van rechtswege in verzuim. Een
              ingebrekestelling is derhalve niet vereist. Afnemer is dan een
              rente verschuldigd van 1,9% per maand of gedeelte van een maand.
              De rente over het opeisbare bedrag zal worden berekend vanaf het
              moment dat Afnemer in verzuim is tot het moment van voldoening van
              het volledig verschuldigde bedrag.{" "}
            </li>
            <li>
              Wanneer de Afnemer zijn verplichtingen niet nakomt zijn alle
              kosten die Deskie maakt voor rekening van Afnemer. De door Deskie
              gemaakte buitengerechtelijke (incasso)kosten worden vastgesteld op
              ten minste 20% van de hoofdsom van de vordering.
            </li>
          </OrderdList>
          <Text h2>Artikel 6. Intellectuele eigendom</Text>
          <OrderdList count="6">
            <li>
              Afnemer erkent dat Deskie alle Intellectuele Eigendomsrechten op
              de Software bezit.{" "}
            </li>
            <li>
              Afnemer zal de Software niet aanpassen, decompileren,
              doorverkopen, distribueren, of anderszins buiten het bedoelde
              gebruik hanteren.
            </li>
            <li>
              Het is Afnemer niet toegestaan enig merk, handelsnaam, ontwerp of
              domeinnaam van Deskie of gelijksoortige daarmee te associëren naam
              te registeren in enig rechtsgebied, waar dan ook ter wereld.
            </li>
            <li>
              Afnemer garandeert dat geen rechten van derden zich verzetten
              tegen beschikbaarstelling van gegevens aan Deskie. Afnemer zal
              Deskie vrijwaren tegen elke actie die gebaseerd is op de bewering
              dat zodanig beschikbaar stellen, gebruiken, bewerken, installeren
              of incorporeren inbreuk maakt op enig recht van derden.
            </li>
          </OrderdList>
          <Text h2>Artikel 7. Aansprakelijkheid</Text>
          <OrderdList count="7">
            <li>
              De afnemer is verantwoordelijk voor het aandragen van correcte en
              representatieve gegevens en informatie die nodig zijn voor het
              uitvoeren van Overeenkomst. Deskie is niet aansprakelijk voor
              schade, onder anderen op basis van een verkeerde bestelling,
              indien Afnemer onjuiste, niet-representatieve of irrelevante
              gegevens heeft verstrekt.{" "}
            </li>
            <li>
              Deskie is niet aansprakelijk voor de gevolgen van overschrijding
              van de termijnen overeenkomstig artikel 2 lid 4 van deze Algemene
              voorwaarden. Overschrijding van de termijn geeft Klant geen recht
              de Diensten te annuleren, of de ontvangst of betaling van de
              Diensten te weigeren.{" "}
            </li>
            <li>
              Deskie is niet aansprakelijk voor fouten of nalatigheden van door
              haar ingeschakelde derden. Door gebruik te maken van de Diensten
              van Deskie verleent Afnemer de bevoegdheid aan Deskie om, als een
              door Deskie ingeschakelde derde zijn aansprakelijkheid wilt
              beperken, die aansprakelijkheidsbeperking mede namens Afnemer te
              aanvaarden.{" "}
            </li>
            <li>
              Deskie is niet aansprakelijk voor indirecte schade, waaronder maar
              niet uitsluitend begrepen gevolgschade zoals: bedrijfsschade,
              stagnatieschade, gemiste besparingen, verminderde goodwill en
              gederfde winst.{" "}
            </li>
            <li>
              Deskie is niet aansprakelijk voor het niet of niet tijdig voldoen
              aan de verplichtingen, voortvloeiend uit Overeenkomst, in geval
              dit veroorzaakt wordt door overmacht als bedoeld in artikel 8.{" "}
            </li>
            <li>
              Indien Deskie aansprakelijk wordt gehouden, zal zij uitsluitend
              aansprakelijk zijn voor directe schade die daadwerkelijk door
              Afnemer is opgelopen, betaald of geleden is vanwege een
              aantoonbaar tekortschieten van de verplichtingen van Deskie,
              waarbij de schade een rechtstreeks en uitsluitend gevolg moet zijn
              van het handelen van Deskie.
            </li>
            <li>
              De aansprakelijkheid van Deskie is beperkt tot het door de
              verzekeraar gedekte en uitgekeerde bedrag. Indien de verzekeraar
              niet tot uitkering overgaat, dan wel indien Deskie niet is
              verzekerd, is de aansprakelijkheid beperkt tot maximaal een bedrag
              gelijk aan de in Overeenkomst tussen Deskie en Afnemer opgenomen
              vergoeding voor een (1) jaar, respectievelijk de gemiddelde
              maandelijkse vergoeding voor de zakenpartner of ontwikkelpartner.{" "}
            </li>
            <li>
              De beperking van de aansprakelijkheid zoals beschreven in dit
              artikel geldt niet indien sprake is van opzet of bewuste
              roekeloosheid aan de zijde van Deskie.{" "}
            </li>
            <li>
              Dit artikel sluit geen aansprakelijkheid uit voor zover
              aansprakelijkheid bij wet niet mag worden beperkt of uitgesloten.{" "}
            </li>
          </OrderdList>
          <Text h2>Artikel 8. Overmacht</Text>
          <OrderdList count="8">
            <li>
              Onder overmacht wordt verstaan: alle van buiten komende oorzaken,
              buiten wil of toedoen van Deskie, waardoor tijdige, volledige of
              juiste nakoming van Overeenkomst niet meer mogelijk is. Hier valt
              onder andere maar niet uitsluitend niet-nakoming van een derde,
              ziekte van personeel van Deskie zelf of een derde onder.{" "}
            </li>
            <li>
              In geval van overmacht wordt nakoming van Overeenkomst opgeschort
              voor zolang de overmacht aanhoudt.{" "}
            </li>
            <li>
              Indien de overmacht drie maanden of langer aanhoudt, zijn beide
              partijen gerechtigd Overeenkomst zonder tussenkomst van de rechter
              te ontbinden. In een dergelijk geval zal Deskie overgaan tot
              terugbetaling van eventueel betaalde bedragen, met daarop in
              mindering gebracht alle kosten die Deskie heeft gemaakt met
              betrekking tot Overeenkomst.
            </li>
          </OrderdList>
          <Text h2>Artikel 9. Onderhoud en updates</Text>
          <OrderdList count="9">
            <li>
              Deskie zal zich inspannen om fouten in de Software op te sporen en
              op te lossen. Als de fout in de Software het gebruik van de
              Software onmogelijk maakt, zal Deskie zich inspannen om de Fout zo
              snel als redelijkerwijs mogelijk is te herstellen.{" "}
            </li>
            <li>
              Deskie kan te allen tijde de Software ontoegankelijk maken om
              updates door te voeren en/of onderhoud aan de Software uit te
              voeren. Deskie zal zich ervoor inspannen om dit op tijden te doen
              dat het voor Afnemer zo min mogelijk hinder veroorzaakt, dat wil
              zeggen buiten kantoortijden. Voor zover redelijk mogelijk zal
              Deskie Afnemer vooraf van het geplande onderhoud op de hoogte
              stellen. Ontoegankelijkheid van de Software door onderhoud of
              updates kan nimmer tot schadeplichtigheid van Deskie leiden.{" "}
            </li>
            <li>
              Deskie zal regelmatig updates in de Software doorvoeren. Afnemer
              werkt daardoor automatisch altijd met de laatste versie van de
              Software. Het is niet mogelijk om met een oudere versie te blijven
              werken.{" "}
            </li>
            <li>
              Deskie zal Afnemer in redelijkheid ondersteunen bij het gebruik
              van de Software, op basis van redelijkheid, ter discretie van
              Deskie.{" "}
            </li>
            <li>
              Deskie is niet verantwoordelijk en niet aansprakelijk voor
              eventuele fouten, storingen, haperingen en/of andere problemen
              in/met de (werking van) Derden Software, ook niet als dit door
              updates, upgrades en/of onderhoudswerkzaamheden in de
              Derden-Software worden veroorzaakt. Afnemer dient in dat geval
              contact op te nemen met de beheerder van de Derden-Software.{" "}
            </li>
            <li>
              Behoudens hetgeen uitdrukkelijk in Overeenkomst en/of in deze
              algemene voorwaarden is opgenomen, biedt Deskie geen andere
              garanties, toezeggingen of voorwaarden met betrekking tot de
              Diensten.
            </li>
          </OrderdList>
          <Text h2>Artikel 10. Privacy</Text>
          <OrderdList count="10">
            <li>
              Afnemer garandeert en staat ervoor dat zij bij het verwerken van
              persoonsgegevens met de Software in lijn handelt met alle op die
              verwerking betrekking hebbende wet- en regelgeving, zoals
              bijvoorbeeld – maar niet uitsluitend – de Algemene Verordening
              Gegevensbescherming (AVG). Afnemer vrijwaart Deskie voor
              aanspraken van derden in dit verband. Afnemer zal met Deskie een
              separate verwerkersovereenkomst sluiten waarin de verwerking van
              persoonsgegevens is geregeld.{" "}
            </li>
            <li>
              Afnemer garandeert en staat er voor in – gelet op alle daarvoor
              geldende wet- en regelgeving – dat het haar is toegestaan
              Ondertekenaars en/of derden via de Software (e-mail)berichten te
              sturen. Afnemer vrijwaart Deskie voor aanspraken van derden in dit
              verband.{" "}
            </li>
            <li>
              Geheimhouding geldt zowel tijdens de looptijd van Overeenkomst
              alsmede na beëindiging van Overeenkomst, behoudens voorafgaande
              schriftelijke toestemming van de andere partij. 9.4. Partijen
              verplichten zich alle op hen rustende verplichtingen op het gebied
              van privacywetgeving na te komen.{" "}
            </li>
          </OrderdList>
          <Text h2>Artikel 11. Ontbinding van de overeenkomst</Text>
          <OrderdList count="11">
            <li>
              Deskie is bevoegd de nakoming van de verplichtingen op te schorten
              of Overeenkomst te ontbinden, indien Afnemer de verplichtingen uit
              Overeenkomst niet, niet volledig of niet tijdig nakomt, dan wel
              dat Deskie goede grond heeft te vrezen dat Afnemer in deze
              verplichtingen zal tekortschieten.{" "}
            </li>
            <li>
              Voorts is Deskie bevoegd Overeenkomst te ontbinden indien zich
              omstandigheden voordoen welke van dien aard zijn dat nakoming van
              Overeenkomst onmogelijk is of ongewijzigde instandhouding van
              Overeenkomst in redelijkheid niet kan worden gevergd.{" "}
            </li>
            <li>
              Indien Afnemer zijn uit Overeenkomst voortvloeiende verplichtingen
              niet nakomt en deze niet nakoming ontbinding rechtvaardigt, dan is
              Deskie gerechtigd Overeenkomst terstond en met directe ingang te
              ontbinden zonder enige verplichting zijnerzijds tot betaling van
              enige schadevergoeding of schadeloosstelling.
            </li>
          </OrderdList>
          <Text h2>Artikel 12. Wijziging van Overeenkomst</Text>
          <OrderdList count="12">
            <li>
              Indien tijdens de uitvoering van Overeenkomst blijkt dat het voor
              een behoorlijke uitvoering daarvan noodzakelijk is om deze te
              wijzigen of aan te vullen, zullen Deskie en Afnemer tijdig en in
              onderling overleg tot aanpassing van Overeenkomst overgaan.
            </li>
            <li>
              Het niet of niet onmiddellijk uitvoeren van de gewijzigde
              Overeenkomst levert geen wanprestatie van Deskie op en is voor
              Afnemer geen grond om Overeenkomst op te zeggen of te ontbinden.{" "}
            </li>
            <li>
              Wijzigingen in de oorspronkelijk gesloten Overeenkomst tussen
              Deskie en Afnemer zijn pas geldig vanaf het moment dat deze
              wijzigingen door middel van een aanvullende of gewijzigde
              Overeenkomst zijn aanvaard door beide partijen. Deze wijziging
              geschiedt schriftelijk.
            </li>
          </OrderdList>
          <Text h2>Artikel 13. Toepasselijk recht</Text>
          <OrderdList count="13">
            <li>
              Op elke overeenkomst tussen Deskie en de Klant is Nederlands recht
              van toepassing.{" "}
            </li>
            <li>
              De toepasselijkheid van het Weens Koopverdrag wordt uitgesloten.{" "}
            </li>
            <li>
              In geval er een geschil voor de rechter moet komen, wordt dit
              uitsluitend beslecht door de bevoegde rechter in het
              arrondissement waar Deskie is gevestigd.{" "}
            </li>
          </OrderdList>
          <Text h2>Artikel 14. Slotbepaling</Text>
          <OrderdList count="14">
            <li>Bij klachten mag Klant een mail sturen naar info@deskie.nl.</li>
            <li>
              Deze Algemene Voorwaarden zijn te vinden op de Website, worden
              gevoegd bij offertes en er wordt naar verwezen op
              bestelformulieren.
            </li>
          </OrderdList>
        </Content>
      </PageWrapper>
    </>
  )
}

export default TermsAndConditions
